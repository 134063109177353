const modalMediaTypes = [4, 8];
const { trackClick } = useAnalytics();
const { openModal } = useModal();

export default function useBlock() {
  const onClick = async (block: Block, modalComponent?: ModalComponent) => {
    const { slug } = useRoute().params;
    const lastHyphenIndex = slug.lastIndexOf("-");
    const collectionHashid = slug.substring(lastHyphenIndex + 1);

    const openNewTab = !block.item.use_same_tab;

    // Record analytics click
    const trackClickParams = { collection: collectionHashid, content: block.hashid };

    // TODO: Refactor
    try {
      openNewTab ? await trackClick(trackClickParams) : trackClick(trackClickParams);
    } catch (error) {}

    // Continue click action, open modal or link
    if (modalComponent && modalMediaTypes.includes(block.item.type.id)) {
      openModal(modalComponent, { item: block.item });
    } else {
      openNewTab ? window.open(block.item.url, "_blank") : (window.location.href = block.item.url);
    }
  };

  return {
    onClick,
  };
}
